import React from 'react';

import StarIcon from '../../assets/images/star-icon.png'
import HowItsWork from '../../assets/images/OS_layers_aws.png'

const HowItWork = () => {
    return (
        <section className="how-its-work-area pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="image" />
                                The Right Choice
                            </span>
                            <h2>Why Outspoke?</h2>
                            <p className="brighter-p">
                              Outspoke organizes and standardizes supply chain data while making it
                              easy for businesses to generate and securely share the insights needed
                              to improve resource allocation and increase sustainability.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowItsWork} alt="image" style={{width: '90%', marginLeft: '5%'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;
