import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'

const yourWords = [
  'your network',
  'retail buyers',
  'the supply chain',
  'your logistics',
  'your suppliers',
  'the supply chain',
  'your customers',
  'your investors',
  'the supply chain',
  'your employees',
  'your partners',
  'the supply chain'
]

const Solutions = () => {
  const [wordIndex, setWordIndex] = useState(0);
  const [animOn, setAnimOn] = useState(true);

  useEffect(() => {
    let i = 0;
    const interval = setInterval(increment, 3000);
    const stop = 9;

    function increment() {
      setAnimOn(false);
      i = i + 1;
      if (i === stop) {
        i = 0;
      }
      setWordIndex(i);
      setAnimOn(true);
    }
  },[])

    return (
        <section className="solutions-area pt-70 pb-20">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="StarIcon" />
                        A new way to manage local and global supply chains
                    </span>
                    <h3 style={{color: '#fff', fontSize: 22, lineHeight: 1.4}}>
                      Outspoke makes it easy to illuminate the data needed to solve the biggest challenge
                      faced by businesses and consumers - <span style={{color: '#FEC344', fontWeight: 'bold'}}>Supply Chain Fragility</span>
                    </h3>
                    <div style={{display: 'flex', marginTop: 20}}>
                      <h2 className="text-4xl lg:text-6xl font-semibold" style={{color: '#fff', paddingLeft: 190, fontWeight: 600}}>
                        Collaborate with <span style={{color: '#8BCFF4'}} className={animOn ? "rotating-word" : "no-rotating-word"}>{yourWords[wordIndex]}.</span>
                      </h2>
                    </div>
                  </div>

                {/*<div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details-two">
                                    Startup Applications
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link to="/service-details-two" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <h3>
                                <Link to="/service-details-two">
                                    SaaS Solutions
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link to="/service-details-two" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>
                            <h3>
                                <Link to="/service-details-two">
                                    eCommerce Platforms
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link to="/service-details-two" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>*/}
            </div>
        </section>
    )
}

export default Solutions;
